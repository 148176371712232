// export const baseUrl = "https://digitalapi.vouch.club";
// export const baseUrl = "https://mastercardapi.vouch.club";
// export const baseUrl = "https://almondsapi.vouch.club";
// export const baseUrl = "http://localhost:3600";
console.log(process.env.REACT_APP_BASE_URL)
// export const baseUrl = process.env.REACT_APP_BASE_URL
export const baseUrl = "https://api.pepgenie.co.in";



    