import axios from "axios";
import { baseUrl } from "../constant";

export const getLoginDetails = async (page, limit, selectedDate) => {
    try {
        const response = await axios.get(`${baseUrl}/api/admin/login-details?type=PEPGENIE&page=${page}&limit=${limit}&selectedDate=${selectedDate}`, {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem('token')}`
            }
        })
        
        return response;
    } catch (err) {
        return err;
    }
}