import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import { baseUrl } from '../constant';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; // Import styles
import PageLayout from '../Layout/Layout';
import { format } from 'date-fns';
import Loader from '../components/loader';
import convertStatusCode from '../services/convertStatusCode';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

// function CustomToolbar() {
//   return (
//     <GridToolbarContainer>
//       <GridToolbarExport />
//     </GridToolbarContainer>
//   );
// }
const Coupans = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  // const [feedback, setFeedback] = useState([]);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10000);
  const [pagination, setPagination] = useState(0);
  const [selectedDate, setSelectedDate] = useState('');
  let [couponId, setCouponId] = useState(0);
  let [refresh, setRefresh] = useState(0);
  let [allExcelData, setAllExcelData] = useState([]);
  const navigate = useNavigate();

  const columns = [
    // { field: 'order_id', headerName: 'Order Id', width: 100 },
    // { field: 'project_name', headerName: 'Project Name', width: 100 },
    { field: 'ind', headerName: 'S.No.', width: 250 },
    { field: 'title', headerName: 'Product Name', width: 250 },
    // { field: 'brand_name', headerName: 'Brand Name', width: 250 },
    // { field: 'sku', headerName: 'Sku', width: 150 },
    // { field: 'mobile', headerName: 'Mobile', width: 150 },
    // { field: 'name', headerxName: 'Name', width: 200 },
    { field: 'coupon_code', headerName: 'Tracking Code', width: 200 },
    { field: 'mobile', headerName: 'User Mobile Number', width: 200 },
    // { field: 'almond_pin', headerName: 'Coupon Pin', width: 150 },
    { field: 'price_point', headerName: 'Coupon Denomination', width: 200 },
    {
      field: 'created_at',
      headerName: 'Order Date',
      width: 200,
      renderCell: (cellValues) => {
        if (cellValues.value) {
          const date = new Date(cellValues.value);
          const yyyy = date.getFullYear();
          let mm = date.getMonth() + 1; // Months start at 0!
          let dd = date.getDate();

          if (dd < 10) dd = '0' + dd;
          if (mm < 10) mm = '0' + mm;
          const formattedToday = `${dd}-${mm}-${yyyy}`; //dd + '/' + mm + '/' + yyyy;
          return <div style={{ margin: 'auto' }}>{formattedToday}</div>;
        } else {
          return <div>N/A</div>;
        }
      },
    },
    {
      field: 'expireAt',
      headerName: 'Expire At',
      width: 200,
      renderCell: (cellValues) => {
        if (cellValues.value) {
          const date = new Date(cellValues.value);
          const yyyy = date.getFullYear();
          let mm = date.getMonth() + 1; // Months start at 0!
          let dd = date.getDate();

          if (dd < 10) dd = '0' + dd;
          if (mm < 10) mm = '0' + mm;
          const formattedToday = `${dd}-${mm}-${yyyy}`; //dd + '/' + mm + '/' + yyyy;
          return <div style={{ margin: 'auto' }}>{formattedToday}</div>;
        } else {
          return <div>N/A</div>;
        }
      },
    },
    {
      field: 'last_login',
      headerName: 'Login Date',
      width: 200,
      renderCell: (cellValues) => {
        if (cellValues.value) {
          const date = new Date(cellValues.value);
          const yyyy = date.getFullYear();
          let mm = date.getMonth() + 1; // Months start at 0!
          let dd = date.getDate();

          if (dd < 10) dd = '0' + dd;
          if (mm < 10) mm = '0' + mm;
          const formattedToday = `${dd}-${mm}-${yyyy}`; //dd + '/' + mm + '/' + yyyy;
          return <div style={{ margin: 'auto' }}>{formattedToday}</div>;
        } else {
          return <div>N/A</div>;
        }
      },
    },
    // {
    //   field: 'status', headerName: 'Status', width: 200,
    //   // renderCell: (cellValues) => {
    //   //   if (cellValues.value === 5)
    //   //     return <div>5</div>;
    //   //   } else if(cellValues.value === 4){
    //   //     return <div>4</div>;
    //   //   }else if(cellValues.value === 3){
    //   //     return <div>Activate</div>;
    //   //   }else if(cellValues.value === 2){
    //   //     return <div>Redeemed</div>;
    //   //   } else{
    //   //     return <div>check status</div>;
    //   //   }
    //   // },
    // },

    // {
    //   field: 'view',
    //   headerName: 'View',
    //   sortable: false,
    //   renderCell: (params) => (
    //     <button className='btn btn-warning' style={{ padding: "5px 10px" }} onClick={() => handleViewClick(params.row.id)}>View</button>
    //   ),
    // },
    // {
    //   field: 'action',
    //   headerName: 'Action',
    //   width: '120',
    //   sortable: false,
    //   renderCell: (params) => {
    //     console.log("params", params)
    //     return <>

    //       {params?.row?.status === "Admin Activate Coupon" ? <button className='btn btn-success' style={{ padding: "5px 10px" }} onClick={() => handleConfirmClick(params.row.id, params.row.coupon_code)}>Activate</button> : <button className='btn btn-success p-1' disabled>Activated</button>}

    //     </>

    //   },
    // },
  ];

  console.log('datat', data);
  const exportToXLSX = (jsonData, columns, fileName) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet 1'); // Removed the extra '-' here

    const headers = Object.keys(jsonData[0]);
    worksheet.addRow(headers);

    jsonData.forEach((item) => {
      const row = [];
      headers.forEach((header) => {
        row.push(item[header]);
      });
      worksheet.addRow(row);
    });

    // workbook.xlsx.writeBuffer().then((buffer) => {
    //   const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
    //   const url = window.URL.createObjectURL(blob);
    //   const a = document.createElement('a');
    //   a.href = url;
    //   a.download = fileName
    //   a.click();
    //   window.URL.revokeObjectURL(url);
    // });
    workbook.csv.writeBuffer().then((buffer) => {
      // Create a Blob and trigger download
      const blob = new Blob([buffer], { type: 'text/csv;charset=utf-8' });
      saveAs(blob, fileName);
    });
  };
  // const exportToXLSX = (jsonData, fileName) => {
  //   const workbook = new ExcelJS.Workbook();
  //   const worksheet = - workbook.addWorksheet('Sheet 1');

  //   const headers = Object.keys(jsonData[0]);
  //   worksheet.addRow(headers);

  //   jsonData.forEach((item) => {
  //     const row = [];
  //     headers.forEach((header) => {
  //       row.push(item[header]);
  //     });
  //     worksheet.addRow(row);

  //   })

  //   workbook.xlxs.writeBuffer().then((buffer)=>{
  //     const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
  //     const url = window.URL.createObjectURL(blob);
  //     const a = document.createElement('a');
  //     a.href = url;
  //     a.download = fileName
  //     a.click();
  //     window.URL.revokeObjectURL(url);
  //   })
  // };

  useEffect(() => {
    console.log(page, size);
    fetchPageData(page, size, selectedDate);
    console.log(page, size);
  }, [page, size, refresh, selectedDate]);

  const handleExportClick = () => {
    const columns = [
      'Product Name',
      'Tracking code',
      'User Mobile Number',
      'Coupon Denomination',
      'Order At',
      'Expire At',
      'Last Login',
      'Order Id',
    ];

    let startIndex = (page - 1) * size + 1;

    // Map your data to fit the specified column names
    const exportData = allExcelData.map((dt, i) => ({
      'Order Id': startIndex + i,
      'Product Name': dt.title,
      'Tracking code': dt.coupon_code,
      'User Mobile Number': dt.mobile,
      'Coupon Denomination': dt.price_point,
      'Order At': dt.created_at,
      'Expire At': dt.expireAt,
      'Last Login': dt.last_login,
    }));

    // Call the export function
    exportToXLSX(exportData, columns, 'coupon_data.csv');
  };

  const handleDateChange = (date) => {
    if (date) {
      const formattedDate = `${date.getFullYear()}-${(
        '0' +
        (date.getMonth() + 1)
      ).slice(-2)}-${('0' + date.getDate()).slice(-2)}`;
      setSelectedDate(formattedDate);
    }
  };

  const handleClearFilter = () => {
    setSelectedDate(''); // Clear selected date
  };

  const handleDateBlur = () => {
    fetchPageData(page, size, selectedDate);
  };

  const fetchPageData = async (page, size, selectedDate) => {
    try {
      const tokenFromLocalStorage = localStorage.getItem('token');
      const configHeaders = {
        headers: { Authorization: `Bearer ${tokenFromLocalStorage}` },
      };

      // Construct the request body with optional selectedDate parameter
      const requestBody = {};
      if (selectedDate) {
        requestBody.selectedDate = selectedDate;
      }

      const response = await axios.post(
        `${baseUrl}/api/admin/coupons/list?type=PEPGENIE&page=${page}&limit=${size}`,
        requestBody,
        configHeaders
      );
      console.log(
        '🚀 ~ file: Coupans.js:50 ~ fetchPageData ~ response:',
        page,
        size,
        response?.data?.data?.coupons
      );

      let result = [];
      let startIndex = (page - 1) * size + 1;
      let excelData = [];

      response?.data?.data?.coupons?.map((dt, i) => {
        result.push({
          ind: startIndex + i,
          // order_id: dt?.order_id,
          // project_name: dt?.project_name,
          title: dt?.title,
          // product_id: dt?.product_id,
          // brand_name: dt?.brand_name,
          // sku: dt?.sku,
          // mobile: dt?.user?.mobile,
          // name: dt?.user?.name,
          coupon_code: dt?.coupon_code,
          mobile: dt?.user?.mobile,
          // almond_pin: dt?.almond_pin,
          price_point: dt?.price_point,
          created_at: dt?.created_at,
          expireAt: dt?.expireAt,
          last_login: dt?.last_login,
          // status: convertStatusCode(dt?.status),
          // category_name: dt?.category_name,
          // product_name: dt?.product_name,
          // cost: dt?.cost,
          id: dt?.id,
        });
        excelData.push({
          ind: startIndex + i,
          // order_id: dt?.order_id,
          // project_name: dt?.project_name,
          title: dt?.title,
          // product_id: dt?.product_id,
          // brand_name: dt?.brand_name,
          // sku: dt?.sku,
          // mobile: dt?.user?.mobile,
          // name: dt?.user?.name,
          coupon_code: dt?.coupon_code,
          mobile: dt?.user?.mobile,
          // almond_pin: dt?.almond_pin,
          price_point: dt?.price_point,
          created_at: dt?.created_at,
          expireAt: dt?.expireAt,
          last_login: dt?.last_login,
          // status: convertStatusCode(dt?.status),
          // category_name: dt?.category_name,
          // product_name: dt?.product_name,
          // cost: dt?.cost,
          // id: dt?.id,
        });
      });
      setAllExcelData(excelData);
      setData(result);
      setLoading(false);
      setPagination(response?.data?.data?.totalCount);
    } catch (error) {
      if (error?.response?.status === 401) {
        navigate('/login');
      }
      console.error('Error fetching data:', error);
    }
  };

  // const handlePageChange = (newPage) => {
  //   console.log("Page changed to:", newPage);
  //   setPage(newPage);
  // };
  // const handlePageSizeChange = (newPageSize) => {
  //   console.log("Page size changed to:", newPageSize);
  //   setSize(newPageSize);
  // };

  const handleViewClick = (id) => {
    // Implement logic to display full details for the selected id
    // You can use a modal or navigate to a separate page/component
    console.log('viewId', id);
    navigate(`/view/${id}`);
  };
  const handleConfirmClick = (id, coupon_code) => {
    // const tokenFromLocalStorage = localStorage.getItem("token");
    // console.log("Coupn_code", coupon_code)
    // const configHeaders = {
    //   headers: { "Authorization": `Bearer ${tokenFromLocalStorage}` },
    // };
    // console.log(configHeaders);

    // axios.post(`${baseUrl}/api/admin/confirm/coupons`, { id: JSON.stringify(id), coupon_code: coupon_code }, configHeaders).then((response) => {
    //   console.log("response", response?.data?.sucess);
    //   if (response?.data?.sucess === true) {
    //     console.log("Came inside")
    //     Swal.fire({
    //       // position: 'top-end',
    //       icon: 'success',
    //       title: 'Coupon Activated Successfully',
    //       showConfirmButton: false,
    //       timer: 1500
    //     })

    //     setRefresh(refresh + 1)
    //   }
    // }).catch((error) => {
    //   console.error('Error fetching data:', error);
    //   Swal.fire({
    //     // position: 'top-end',
    //     icon: 'success',
    //     title: 'Something Went Wrong',
    //     showConfirmButton: false,
    //     timer: 1500
    //   })
    // });
    Swal.fire({
      title: 'Do you want to activate the Coupon',
      icon: 'info',
      showDenyButton: true,
      // showCancelButton: true,
      confirmButtonText: 'OKAY',
      denyButtonText: `Cancel`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        const tokenFromLocalStorage = localStorage.getItem('token');
        console.log('Coupn_code', coupon_code);
        const configHeaders = {
          headers: { Authorization: `Bearer ${tokenFromLocalStorage}` },
        };
        console.log(configHeaders);

        axios
          .post(
            `${baseUrl}/api/admin/confirm/coupons`,
            { id: JSON.stringify(id), coupon_code: coupon_code },
            configHeaders
          )
          .then((response) => {
            console.log('response', response?.data?.sucess);
            if (response?.data?.sucess === true) {
              Swal.fire('Activated', '', 'success');
              console.log('Came inside');
              setRefresh(refresh + 1);
            }
          })
          .catch((error) => {
            console.log('Error', error);
          });
      } else if (result.isDenied) {
        Swal.fire('Changes are not saved', '', 'info');
      }
    });
  };

  console.log('data', data);
  return (
    <PageLayout>
      {loading ? (
        <Loader />
      ) : (
        <div
          className="ms-4"
          style={{ height: 400, width: '95%', marginTop: '10px' }}
        >
          <div className="d-flex justify-content-between align-items-center">
            <button
              type="button"
              className="btn btn-warning ms-3 mb-2 mt-2"
              onClick={() => {
                navigate(-1);
              }}
            >
              Go Back
            </button>
            <div className="d-flex align-items-center justify-content-end">
              <DatePicker
                selected={selectedDate}
                onChange={handleDateChange}
                placeholderText="Select date"
                onBlur={handleDateBlur}
                dateFormat="dd-MM-yyyy" // Adjust date format as needed
                className="me-3"
                maxDate={new Date()}
              />
              {selectedDate && ( // Render clear filter button only if a date is selected
                <button
                  style={{ marginRight: '5px' }}
                  className="btn btn-info mb-2"
                  type="button"
                  onClick={handleClearFilter}
                >
                  Clear Filter
                </button>
              )}
              {data.length > 0 && (
                <button
                  className="btn btn-info mb-2"
                  type="button"
                  onClick={handleExportClick}
                >
                  Download
                </button>
              )}
            </div>
          </div>

          <DataGrid
            sx={{ marginLeft: '15px' }}
            rows={data}
            columns={columns}
            rowsPerPageOptions={[10, 20, 50, 100]}
            // pagination
            // pageSize={size}
            // rowCount={pagination}
            // paginationMode="client"
            // onPageChange={(newPage) => setPage(newPage + 1)}
            // onPageSizeChange={(newPageSize) => setSize(newPageSize)}
            // components={{
            //   Toolbar: CustomToolbar,
            // }}
            autoHeight
            {...data}
            // slots={{ toolbar: GridToolbar }}
            components={{
              NoRowsOverlay: () => (
                <div
                  style={{ fontSize: '20px', margin: '10px' }}
                  className="result-not-found"
                >
                  Data Not Found
                </div>
              ),
            }}
          />
        </div>
      )}
    </PageLayout>
  );
};

export default Coupans;
