import React, { useEffect, useState } from 'react';
import { getAllUsersData } from '../services/getAllUsersData';
import PageLayout from '../Layout/Layout';
import { useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; // Import styles
import { DataGrid } from '@mui/x-data-grid';
import Swal from 'sweetalert2';
import Loader from '../components/loader';
import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

function AllUsersData() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10000);
  const [pagination, setPagination] = useState(0);
  const [selectedDate, setSelectedDate] = useState(null); // Initialize selectedDate as null
  let [allExcelData, setAllExcelData] = useState([]);
  const navigate = useNavigate();

  const columns = [
    { field: 's_no', headerName: 'S.No.', width: 150 },
    { field: 'id', headerName: 'Id', width: 150 },
    { field: 'mobile', headerName: 'Mobile', width: 250 },
    {
      field: 'status',
      headerName: 'Status',
      width: 200,
      renderCell: (cellValues) => {
        return (
          <div style={{ margin: 'auto' }}>
            {cellValues.value === 1 ? 'Active' : 'Inactive'}
          </div>
        );
      },
    },
    {
      field: 'created_at',
      headerName: 'Created At',
      width: 200,
      renderCell: (cellValues) => {
        if (cellValues.value) {
          const date = new Date(cellValues.value);
          const formattedToday = `${date
            .getDate()
            .toString()
            .padStart(2, '0')}-${(date.getMonth() + 1)
            .toString()
            .padStart(2, '0')}-${date.getFullYear()}`;
          return <div style={{ margin: 'auto' }}>{formattedToday}</div>;
        } else {
          return <div>N/A</div>;
        }
      },
    },
    {
      field: 'eyeButton',
      headerName: 'Action',
      width: 100,
      renderCell: (params) => (
        <IconButton
          style={{ margin: 'auto' }}
          onClick={() => handleEyeButtonClick(params.row.id)}
          color="primary"
        >
          <VisibilityIcon />
        </IconButton>
      ),
    },
  ];

  const handleEyeButtonClick = (id) => {
    console.log(`Eye button clicked for id: ${id}`);
    navigate(`/view/${id}`);
  };

  const getAllData = async (page, size, selectedDate) => {
    try {
      const response = await getAllUsersData(page, size, selectedDate);

      if (response.data.success) {
        let result = [];
        response.data.data.users?.forEach((data, i) => {
          result.push({
            s_no: (page - 1) * size + i + 1,
            id: data?.id,
            name: data?.name,
            mobile: data?.mobile,
            status: data?.status,
            created_at: data?.created_at,
            updated_at: data?.updated_at,
          });
        });
        setData(result);
        setAllExcelData(result);
        setLoading(false);
        setPagination(response?.data?.data?.totalCount);
      } else {
        Swal.fire({
          title: '',
          text: 'Error while fetching users details',
          icon: 'error',
          showCancelButton: false,
          confirmButtonText: 'Got it',
        }).then((result) => {
          if (result.isConfirmed) {
            navigate('/main');
          }
        });
      }
    } catch (err) {
      console.log('Error', err);
    }
  };

  useEffect(() => {
    getAllData(page, size, selectedDate);
  }, [page, size, selectedDate]);

  const handleDateChange = (date) => {
    if (date) {
      const formattedDate = `${date.getFullYear()}-${(
        '0' +
        (date.getMonth() + 1)
      ).slice(-2)}-${('0' + date.getDate()).slice(-2)}`;
      setSelectedDate(formattedDate);
    }
  };

  const handleClearFilter = () => {
    setSelectedDate(''); // Clear selected date
  };

  const handleExportClick = () => {
    exportToXLSX(allExcelData, 'user.csv');
  };

  const exportToXLSX = (jsonData, fileName) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet 1'); // Removed the extra '-' here
    const headers = Object.keys(jsonData[0]);
    worksheet.addRow(headers);
    jsonData.forEach((item) => {
      const row = [];
      headers.forEach((header) => {
        row.push(item[header]);
      });
      worksheet.addRow(row);
    });
    workbook.csv.writeBuffer().then((buffer) => {
      // Create a Blob and trigger download
      const blob = new Blob([buffer], { type: 'text/csv;charset=utf-8' });
      saveAs(blob, fileName);
    });
  };

  return (
    <PageLayout>
      {loading ? (
        <Loader />
      ) : (
        <div
          className="ms-4"
          style={{ height: 400, width: '95%', marginTop: '10px' }}
        >
          <div className="d-flex justify-content-between align-items-center">
            <button
              type="button"
              className="btn btn-warning ms-3 mb-2 mt-2"
              onClick={() => {
                navigate(-1);
              }}
            >
              Go Back
            </button>
            <div className="d-flex align-items-center justify-content-end">
              <DatePicker
                selected={selectedDate}
                onChange={handleDateChange}
                placeholderText="Select date"
                // onBlur={handleDateBlur}
                dateFormat="dd-MM-yyyy" // Adjust date format as needed
                className="me-3"
                maxDate={new Date()}
              />
              {selectedDate && ( // Render clear filter button only if a date is selected
                <button
                  style={{ marginRight: '5px' }}
                  className="btn btn-info mb-2"
                  type="button"
                  onClick={handleClearFilter}
                >
                  Clear Filter
                </button>
              )}
              {data.length > 0 && (
                <button
                  className="btn btn-info mb-2"
                  type="button"
                  onClick={handleExportClick}
                >
                  Download
                </button>
              )}
            </div>
          </div>

          <DataGrid
            sx={{ marginLeft: '15px' }}
            rows={data}
            columns={columns}
            rowsPerPageOptions={[10, 20, 50, 100]}
            autoHeight
            {...data}
            components={{
              NoRowsOverlay: () => (
                <div
                  style={{
                    fontSize: '20px',
                    margin: '10px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  className="result-not-found"
                >
                  Data Not Found
                </div>
              ),
            }}
          />
          <style>{`
          .css-6hp17o-MuiList-root-MuiMenu-list{
            display:flex;
            flex-direction:column;
          }
        
          `}</style>
        </div>
      )}
    </PageLayout>
  );
}

export default AllUsersData;
