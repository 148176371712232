import React, { useEffect, useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import convertStatusCode from "../services/convertStatusCode";

function CouponCard({ coupon }) {
  const [couponData, setCouponData] = useState(null);
  const [usersData, setUsersData] = useState(null);
  const [showFullImage, setShowFullImage] = useState(false);
  const [imageData, setImageData] = useState(null);
  let userData = null;

  useEffect(() => {
    if (coupon) {
      const array = Object.keys(coupon);
      const mapped = array.map((e, index) => {
        // console.log("e",e, index)
        if(e === "user"){
          userData = coupon[e]
        }
        let value = coupon[e];
        if (
          e === "created_at" ||
          e === "updated_at" ||
          e === "expireAt" ||
          e === "publishDate" ||
          e === "qwickcilver_expiry"
        ) {
          value = formatDate(value);
        } else if (e === "image") {
          setImageData(value);
          return null;
        }
        const obj = { label: e, value };
        return obj;
      });
      console.log("mapeed", mapped, "cou[on", coupon)
      // setCouponData(
      //   mapped.filter(
      //     (item) =>

      //       item !== null && item.value !== undefined && item.value !== null
      //   )
      // )


    }
    // console.log("userData", userData)
    setUsersData(userData);
    setCouponData(coupon);
  }, [coupon]);

  

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", options);
  };

  const handleOpenImage = () => {
    setShowFullImage(true);
  };
  const handleCloseImage = () => {
    setShowFullImage(false);
  };
  return (
    <div className="mc-product-item">
      <div className="mc-product-image-container">
        <div class="card" style={{width: '12rem', height: '250px', backgroundColor: 'white', border: "none", zIndex: ''}}>
        <img
          className="mc-product-image"
          src={couponData?.image || "images/coupon.png"}
          alt=""
        />
        </div>
        
        <div className="mc-product-desc-container mc-coupon-code-container" style={{marginLeft: '-13px'}}>
          <h3 className="mc-product-name" style={{fontSize: '20px'}}>{couponData?.coupon_code || "__"}</h3>
          <div className="mc-price-container">
            {/* <span className="min-price">{coupon?.currency?.symbol || ""}{coupon?.cost || "₹100"}</span> */}
            <span>MRP: {" "}</span>
            <span className="max-price">
              {couponData?.currency?.symbol || ""}
              {couponData?.price_point || ""}
            </span>
          </div>
        </div>
      </div>

      <div className="mc-product-desc-container description">
        <p className="mc-coupon-label">Title</p>
        <h3 className="mc-coupon-title">{coupon?.title || "__"}</h3>
        <p className="mc-coupon-label">Description</p>
        <h3 className="mc-coupon-description" style={{maxWidth: '200px'}}>{couponData?.description || "__"}</h3>
      </div>

      <div className="mc-product-desc-container description">
        <p className="mc-coupon-label">SKU</p>
        <h3 className="mc-coupon-title">{couponData?.sku || "__"}</h3>
        <p className="mc-coupon-label">Brand Name</p>
        <h3 className="mc-coupon-description">{couponData?.brand_name || "__"}</h3>
        <p className="mc-coupon-label">Transaction Id</p>
        <h3 className="mc-coupon-title">{couponData?.transaction_id?couponData.transaction_id:"N/A"}</h3>
        <p className="mc-coupon-label">Status</p>
        <h3 className="mc-coupon-title">{convertStatusCode( couponData?.status)}</h3>
      </div>

      <div className="mc-product-desc-container description">
      <p className="mc-coupon-label">Redeem Date</p>
        <h3 className="mc-coupon-title">{couponData?.redeem_date?formatDate(couponData.redeem_date):"N/A"}</h3>
        <p className="mc-coupon-label">Last Login</p>
        <h3 className="mc-coupon-title">{couponData?.last_login?formatDate(couponData.last_login):"N/A"}</h3>
        <p className="mc-coupon-label">Created Date</p>
        <h3 className="mc-coupon-title">{couponData?.created_at?formatDate(couponData.created_at):"N/A"}</h3>
      </div>
    </div>
  );
}

export default CouponCard;
