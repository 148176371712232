import axios from "axios";
import { baseUrl } from "../constant";

export const getAllUsersData = async (page, limit, selectedDate) => {
    try {
        
        const requestBody = {};
        if (selectedDate) {
            requestBody.selectedDate = selectedDate;
        }

    // console.log("page", page, "limit",limit, localStorage.getItem('token'))
        const response = await axios.post(`${baseUrl}/api/admin/users/list?page=${page}&limit=${limit}`,
        requestBody, {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem('token')}`
            }
        })
        
        return response;
    } catch (err) {
        return err;
    }
}