import axios from "axios";
import { baseUrl } from "../constant";

export const getUserDetails = async (user_id) => {
    try {
        const response = await axios.get(`${baseUrl}/api/admin/user-details?type=PEPGENIE&userId=${user_id}`, {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem('token')}`
            }
        })
        return response;
    } catch (err) {
        return err;
    }
}