import { React, useState } from 'react';
import Swal from 'sweetalert2';
import { css } from '@emotion/react';
import { ClipLoader } from 'react-spinners';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { faLock, faEyeSlash, faEye } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { baseUrl } from '../constant';
import { useNavigate } from 'react-router-dom';

const spinnerCSS = css`
  display: flex;
  margin-top: 10px;
  justify-content: center;
  align-items: center;
  height: 70vh;
`;
function LoginPage() {
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  // const [projectName, setProjectName] = useState("");

  const validate = () => {
    console.log('doing validate');
    let emailErroMess = '';
    let passwordErrorMess = '';

    if (!email) {
      emailErroMess = 'This Feild is required';
    }
    if (!password) {
      passwordErrorMess = 'This Feild is required';
    }

    if (emailErroMess || passwordErrorMess) {
      setEmailError(emailErroMess);
      setPasswordError(passwordErrorMess);
      return false;
    }
    return true;
  };
  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const emailInputHandler = (e) => {
    console.log('e.target.value : ', e.target.value);
    setEmail(e.target.value);
    setEmailError('');
  };
  const passInputHandler = (e) => {
    console.log('e.target.value : ', e.target.value);
    setPassword(e.target.value);
    setPasswordError('');
  };

  const submitHandler = (e) => {
    setIsLoading(true);
    console.log('clicked to submit handler');
    if (validate()) {
      console.log('all fields validate');
      const body = {
        email,
        password,
      };

      axios
        .post(`${baseUrl}/api/admin/login`, body)
        .then((resAfterLogin) => {
          console.log('resAfterLogin : ', resAfterLogin.data);
          if (resAfterLogin.data.success) {
            console.log('ddddddd ; ');
            localStorage.setItem('user', 'signed');
            localStorage.setItem('token', resAfterLogin.data.token);

            setIsLoading(false);
            navigate('/');
          } else {
            setIsLoading(false);
            console.log(
              'resAfterLogin in else for error : ',
              resAfterLogin.data.message
            );
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: resAfterLogin.data.message,
            });
          }
        })
        .catch((errorWhileLogin) => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: errorWhileLogin.response?.data?.message,
          });
          setIsLoading(false);
          console.log('errorWhileLogin : ', errorWhileLogin);
        });
    } else {
      console.log('fields not filled');
      setIsLoading(false);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      setIsLoading(true);
      console.log('clicked to submit handler');
      if (validate()) {
        console.log('all fields validate');
        const body = {
          email,
          password,
        };

        axios
          .post(`${baseUrl}/api/admin/login`, body)
          .then((resAfterLogin) => {
            console.log('resAfterLogin : ', resAfterLogin.data);
            if (resAfterLogin.data.success) {
              console.log('ddddddd ; ');
              localStorage.setItem('user', 'signed');
              localStorage.setItem('token', resAfterLogin.data.token);

              setIsLoading(false);
              navigate('/');
            } else {
              setIsLoading(false);
              console.log(
                'resAfterLogin in else for error : ',
                resAfterLogin.data.message
              );
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: resAfterLogin.data.message,
              });
            }
          })
          .catch((errorWhileLogin) => {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: errorWhileLogin.response?.data?.message,
            });
            setIsLoading(false);
            console.log('errorWhileLogin : ', errorWhileLogin);
          });
      } else {
        console.log('fields not filled');
        setIsLoading(false);
      }
    }
  };
  return (
    <>
      <div className="col customcard">
        <div className="card sub-card" style={{ borderRadius: '1rem' }}>
          <div className="row g-0">
            {/* <div className="col-md-6 col-lg-5 d-flex">
              <img
                src={"/loginpageimage.png"}
                alt="login form"
                className="img-fluid loginimg cover"
                style={{ borderRadius: "1rem 0 0 1rem" }}
              />
            </div> */}
            <div className="col-md-12 col-lg-12 d-flex align-items-center">
              <div className="card-body p-4 p-lg-5 text-black">
                <form className="mc-login-form">
                  <div className="d-flex align-items-center justify-content-center mb-3 pb-1">
                    <div className="h1 fw-bold mb-0 almond-logo">
                      <img
                        className="img-fluid login-page-logo"
                        src="/images/almond-logo.png"
                      />
                    </div>
                  </div>
                  <h3
                    className="fs-5 mb-3 pb-3 text-center"
                    style={{ letterSpacing: '1px' }}
                  >
                    Login to your account
                  </h3>
                  <div className="form-outline mb-4 input-with-icon">
                    <label className="form-label" htmlFor="form2Example17">
                      Email
                    </label>
                    <div className="mc-input-field-container">
                      <FontAwesomeIcon
                        icon={faUser}
                        className="icon mc-login-field-icon"
                      />{' '}
                      <input
                        type="email"
                        id="form1"
                        placeholder="Type Your Email"
                        onChange={emailInputHandler}
                        value={email}
                        className="form-control form-control-lg small-placeholder"
                      />
                    </div>
                    <span
                      className="text-danger"
                      style={{ display: 'flex', fontSize: '12px' }}
                    >
                      {emailError}
                    </span>
                  </div>

                  <div className="form-outline mb-4 input-with-icon">
                    <div className="relative-container">
                      <label className="form-label" htmlFor="form2Example27">
                        {' '}
                        Password
                      </label>
                      <div className="mc-input-field-container">
                        <FontAwesomeIcon
                          icon={faLock}
                          className="icon mc-login-field-icon"
                        />
                        <input
                          type={showPassword ? 'text' : 'password'}
                          placeholder="Type Your Password"
                          id="form2"
                          onChange={passInputHandler}
                          onKeyPress={handleKeyPress}
                          className="form-control form-control-lg small small-placeholder"
                        />
                        <div className="icon-container">
                          <FontAwesomeIcon
                            icon={showPassword ? faEyeSlash : faEye}
                            className="icon"
                            onClick={handleTogglePassword}
                          />
                        </div>
                      </div>

                      <span
                        className="text-danger"
                        style={{ display: 'flex', fontSize: '12px' }}
                      >
                        {passwordError}
                      </span>
                    </div>
                  </div>

                  <div className="pt-1 mb-4 d-flex align-items-center justify-content-center">
                    {isLoading ? (
                      <ClipLoader
                        css={spinnerCSS}
                        size={50}
                        color={'#e8714b'}
                      />
                    ) : (
                      <button
                        className="btn btn-lg btn-block buttonlogin"
                        onClick={submitHandler}
                        onkeyP
                        type="button"
                      >
                        Login
                      </button>
                    )}
                  </div>
                  {/* <a className="small text-muted" href="#!">Forgot password?</a>
                                                <p className="mb-5 pb-lg-2" style={{color: "#393f81"}}>Don't have an account? <a href="#!"
                                                    style={{color: "#393f81"}}>Register here</a></p>
                                                <a href="#!" className="small text-muted">Terms of use.</a>
                                                <a href="#!" className="small text-muted">Privacy policy</a> */}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <style>{`
      
      .customcard{
        padding:0;
        height:100vh;
        display:flex;
        justify-content:center;
        align-items:center;
        margin:auto;
  
      }
      .login-page-logo{
        max-width:300px !important;
      }

       .card-body{
        display: flex;
        align-items: center;
        justify-content: center;
       }

      .sub-card{
        width: 50%;
        height:65%;
      }
  
      .mc-login-form{
        width:70% !important;
      }
      
      .mc-login-form input{
        font-size:18px;
        border:none;
        width:100%;
        padding:0 0 0 2rem;
      }
      .mc-login-form input:active,.mc-login-form input:hover, .mc-login-form input:focus, .mc-login-form input:focus-visible{
        border:none !important;
        outline:none !important;
      }
      .mc-login-form label{
        font-size:18px;
      }
      .mc-login-form input::placeholder{
        font-size:16px;

      }
      .icon-container{
        position:absolute;
        right:15px;
      }
      .buttonlogin, .buttonlogin:hover{
        background:#1e2c42;
        color:#fff;
        border:none;
        outline:none;
        width:100%; 
        font-size:22px;
      }
      .loginimg{
        object-fit:cover;
      }
      .mc-input-field-container{
        width:100%;
        display:flex;
        align-items:center;
        border:1px solid #dde;
        border-radius:10px;
        position:relative;
      }
      .mc-login-field-icon{
        position:absolute;

      }
      .sub-card {
        width: 50%;
        height:90%;
      }
      .mc-login-field-icon{
        position:absolute;
        padding:0.5rem;
      }
      .almond-logo{
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .login-page-logo{
        width: 60%;
      }
      @media only screen and (max-width: 767px) {
        .sub-card {
          width: 70%;
          height:86%        }}

          @media only screen and (max-width: 560px) {
            .sub-card {
              width: 90%;
              height:85%        }}

              @media only screen and (max-width: 450px) {
                .sub-card {
                  width: 100%;
                  height:90%        }}
      `}</style>
    </>
  );
}

export default LoginPage;
