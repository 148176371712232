import React, { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";
import { GridMenuIcon } from "@mui/x-data-grid";
import SideBar from "./SideBar";
import "../index.css"
const Navbar = () => {
  const [btn, setBtn] = useState(false);
  const [isClicked, setIsClicked] = useState(true);
  

  const navigate = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setBtn(true);
    } else {
      navigate("/login")
    }
  });

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    navigate("/login");
  }
  
  const toggleSidebar = () => {
    const screenWidth = window.screen.width;
    const sidebar = document.querySelector(".mc-sidebar");
  
    if (screenWidth > 481) {
      setIsClicked(!isClicked);
      if (isClicked) {
        sidebar.style.display = "none";
      } else {
        sidebar.style.display = "block";
      }
    } else {
      if (sidebar.style.display === "none" || sidebar.style.display === "") {
        sidebar.style.display = "block"; // Ensure sidebar is visible for larger screens if it was hidden
      } else {
        sidebar.style.display = ""; // Allow the default behavior (CSS rules) for larger screens
      }
    }
  };

  return (
    <Box sx={{ marginBottom: "65px" }}>
      <AppBar position="fixed">
        <Toolbar>
          <div className="nav-menu-icon">
          <IconButton edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }}>
            <GridMenuIcon onClick={toggleSidebar}/>
          </IconButton>
          </div>

          {/* <img src="/images/almond-logo.png" width={150} height={80}/> */}
          {/* <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Coupan Admin Panel
          </Typography> */}
          {btn ? (
            <Button
              color="inherit"
              variant="contained"
              sx={{
                marginLeft: "auto",
                marginRight: "30px",
                width: "120px",
                height: "43px",
                background: "white",
                color: "black",
                fontWeight: "bold",
                fontSize: "18px",
              }}
              className="btn-login"
              onClick={handleLogout}
            >
              LogOut
            </Button>
          ) : (
            <Button
              color="inherit"
              variant="contained"
              sx={{
                marginLeft: "auto",
                width: "120px",
                height: "43px",
                fontSize: "18px",
                background: "white",
                color: "black",
                fontWeight: "bold",
              }}
              className="btn-login"
            >
              Login
            </Button>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Navbar;
