import React, { useEffect, useState } from 'react'
import { getAmountCouponStatus } from '../services/getAmountCouponStatus';
import PageLayout from '../Layout/Layout';
import { useNavigate } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import Swal from 'sweetalert2';
import Loader from '../components/loader';
import ExcelJS from 'exceljs';
import { saveAs } from "file-saver";

function Amount() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true)
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(10);
    const [pagination, setPagination] = useState(0);
    let [refresh, setRefresh] = useState(0);
    let [allExcelData, setAllExcelData] = useState([])
    const navigate = useNavigate();

    const columns = [
        { field: 'id', headerName: 'Id', width: 150 },
        // { field: 'project_name', headerName: 'Project Name', width: 100 },
        { field: 'price_point', headerName: 'Amount', width: 200 },
        { field: 'totalCoupons', headerName: 'Total Coupons', width: 200 },
        { field: 'voucherGenerated', headerName: 'Voucher Generated', width: 200 },
        { field: 'totalUsedCoupons', headerName: 'Voucher Activated', width: 200 },
        // { field: 'voucherGenerated', headerName: 'Voucher Generated', width: 200 },
        // { field: 'totalUnusedCoupons', headerName: 'Total Unused Coupons', width: 200 },
        // { field: 'totalExpiredCoupons', headerName: 'Total Expired Coupons', width: 200 },
        // {
        //     field: 'status', headerName: 'Status', width: 200,
        //     renderCell: (cellValues) => {
        //       if (cellValues.value === "1") {
        //         return <div>Active</div>;
        //       } else{
        //         return <div>Inactive</div>;
        //       }
        //     }
        // },
        // {
        //     field: 'created_at', headerName: 'Created At', width: 200,
        //     renderCell: (cellValues) => {
        //         if (cellValues.value) {
        //             const date = new Date(cellValues.value);
        //             const yyyy = date.getFullYear();
        //             let mm = date.getMonth() + 1; // Months start at 0!
        //             let dd = date.getDate();

        //             if (dd < 10) dd = "0" + dd;
        //             if (mm < 10) mm = "0" + mm;
        //             const formattedToday = `${dd}-${mm}-${yyyy}`; //dd + '/' + mm + '/' + yyyy;
        //             return <div>{formattedToday}</div>;
        //         } else {
        //             return <div>N/A</div>;
        //         }
        //     },
        // },
    ];

    const exportToXLSX = (jsonData, fileName) => {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Sheet 1'); // Removed the extra '-' here
    
        const headers = Object.keys(jsonData[0]);
        worksheet.addRow(headers);
    
        jsonData.forEach((item) => {
          const row = [];
          headers.forEach((header) => {
            row.push(item[header]);
          });
          worksheet.addRow(row);
        });
    
        // workbook.xlsx.writeBuffer().then((buffer) => {
        //   const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
        //   const url = window.URL.createObjectURL(blob);
        //   const a = document.createElement('a');
        //   a.href = url;
        //   a.download = fileName
        //   a.click();
        //   window.URL.revokeObjectURL(url);
        // });
        workbook.csv.writeBuffer().then((buffer) => {
          // Create a Blob and trigger download
          const blob = new Blob([buffer], { type: 'text/csv;charset=utf-8' });
          saveAs(blob, fileName);
        });
      };


    const getAllData = async (page, size) => {
        try {
            const response = await getAmountCouponStatus(page, size);
    
            console.log("page Number", page, "limit ", size, "response", response.data.result);
    
            if (response.data.success) {
                let result = [];
                let startIndex = (page - 1) * size + 1;
                let excelData = [];
                response.data.result.forEach((data, i) => {
                    console.log("data", data);
                    result.push({
                        id: startIndex + i,
                        // ind: startIndex + i,
                        price_point: data?.price_point,
                        totalCoupons: data?.totalCoupons,
                        totalUsedCoupons: data?.totalUsedCoupons,
                        voucherGenerated: data?.voucherGenerated,
                        // totalUnusedCoupons: data?.totalUnusedCoupons,
                        // totalExpiredCoupons: data?.totalExpiredCoupons,
                    });
                    excelData.push({
                        id: startIndex + i,
                        // ind: startIndex + i,
                        price_point: data?.price_point,
                        totalCoupons: data?.totalCoupons,
                        totalUsedCoupons: data?.totalUsedCoupons,
                        voucherGenerated: data?.voucherGenerated,
                        })
                });
                setAllExcelData(excelData)
                setData(result);
                setLoading(false);
                // Assuming the pagination is part of the response
                setPagination(response?.data?.result?.length);


            } else {
                Swal.fire({
                    title: '',
                    text: 'Error while fetching users details',
                    icon: 'error',
                    showCancelButton: false,
                    confirmButtonText: 'Got it',
                }).then((result) => {
                    if (result.isConfirmed) {
                        navigate("/main");
                    }
                });
            }
        } catch (err) {
            console.log("Error", err)
        }


    }

    const handleExportClick = () => {
        exportToXLSX(allExcelData , 'coupon_amount_status.csv')
      }

    useEffect(() => {
        console.log(page, size);
        getAllData(page, size);

    }, [page, size])
    return (
        <>
            <PageLayout>
                <div className='ms-4'>  {loading ? (
                    <Loader />
                ) : data?.length === 0 ? (
                    <div className="result-not-found">Data Not Found</div>
                ) : (
                    <>
                        <div className='ms-4' style={{ height: 400, width: '95%', marginTop: '10px' }}>
                            <div className='d-flex justify-content-between '>
                                <button type='button' className='btn btn-warning ms-3 mb-2 mt-2' onClick={() => {
                                    navigate(-1)
                                }}>Go Back</button>
                                <button className='btn btn-info ms-3 mb-2' type='button' onClick={handleExportClick} >download</button>
                            </div>


                            <DataGrid sx={{ marginLeft: '15px' }}
                                rows={data}
                                columns={columns}
                                rowsPerPageOptions={[10, 20, 50]}
                                pagination
                                pageSize={size}
                                rowCount={pagination}
                                paginationMode="server"
                                onPageChange={(newPage) => {
                                    setPage(newPage + 1)
                                    console.log(newPage)
                                }}
                                onPageSizeChange={(newPageSize) => setSize(newPageSize)}

                                autoHeight
                                {...data}
                            />
                        </div>
                    </>
                )}</div>

            </PageLayout >
        </>
    )
}

export default Amount