import React, { useEffect, useState } from 'react';
import { getLoginDetails } from '../services/getLoginDetails';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; // Import styles
import PageLayout from '../Layout/Layout';
import { useNavigate } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import Swal from 'sweetalert2';
import Loader from '../components/loader';
import { format } from 'date-fns';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';

function LoginDetails() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10000);
  const [pagination, setPagination] = useState(0);
  const [selectedDate, setSelectedDate] = useState(''); // Added state for selected date
  let [refresh, setRefresh] = useState(0);
  let [allExcelData, setAllExcelData] = useState([]);
  const navigate = useNavigate();

  const columns = [
    { field: 'id', headerName: 'Id', width: 150 },
    { field: 'mobile', headerName: 'Mobile', width: 200 },
    {
      field: 'created_at',
      headerName: 'Created At',
      width: 300,
      renderCell: (cellValues) => {
        if (cellValues.value) {
          const date = new Date(cellValues.value);
          const options = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            timeZone: 'Asia/Kolkata',
          };
          const formattedDate = date.toLocaleString('en-IN', options);
          return <div style={{ margin: 'auto' }}>{formattedDate}</div>;
        } else {
          return <div>N/A</div>;
        }
      },
    },
    { field: 'transaction_id', headerName: 'Transaction Id', width: 300 },
    { field: 'latitude', headerName: 'Latitude', width: 200 },
    { field: 'longitude', headerName: 'Longitude', width: 200 },
    {
      field: 'viewMap',
      headerName: 'Location',
      width: 200,
      renderCell: (cellValues) => {
        const { latitude, longitude } = cellValues.row;
        if (latitude && longitude) {
          return (
            <FontAwesomeIcon
              style={{ margin: 'auto', cursor: 'pointer' }}
              icon={faMapMarkerAlt}
              onClick={() => handleViewMapClick(latitude, longitude)}
            />
          );
        } else {
          return <div>No Location Available</div>;
        }
      },
    },
    { field: 'IP', headerName: 'IP', width: 200 },
    { field: 'Device', headerName: 'Device', width: 200 },
    {
      field: 'status',
      headerName: 'Status',
      width: 200,
      renderCell: (cellValues) => {
        if (cellValues.value === '1') {
          return <div style={{ margin: 'auto' }}>OTP Verified</div>;
        } else {
          return <div style={{ margin: 'auto' }}>Pending</div>;
        }
      },
    },
  ];

  const handleViewMapClick = (latitude, longitude) => {
    const mapUrl = `https://maps.google.com/?q=${latitude},${longitude}`;
    window.open(mapUrl, '_blank');
  };

  const exportToXLSX = (jsonData, fileName) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet 1'); // Removed the extra '-' here
    const headers = Object.keys(jsonData[0]);
    worksheet.addRow(headers);
    jsonData.forEach((item) => {
      const row = [];
      headers.forEach((header) => {
        row.push(item[header]);
      });
      worksheet.addRow(row);
    });
    workbook.csv.writeBuffer().then((buffer) => {
      // Create a Blob and trigger download
      const blob = new Blob([buffer], { type: 'text/csv;charset=utf-8' });
      saveAs(blob, fileName);
    });
  };

  const getAllData = async (page, size, selectedDate) => {
    try {
      const response = await getLoginDetails(page, size, selectedDate);
      console.log(
        'page Number',
        page,
        'limit ',
        size,
        'response',
        response.data.result
      );
      if (response.data.success) {
        let result = [];
        let startIndex = (page - 1) * size + 1;
        let excelData = [];
        response.data.result.data.forEach((data, i) => {
          console.log('data', data);
          result.push({
            id: startIndex + i,
            mobile: data?.mobile,
            status: data?.status,
            created_at: data?.created_at,
            transaction_id: data?.transaction_id,
            latitude: data?.latitude,
            longitude: data?.longitude,
            IP: data?.IP,
            Device: data?.Device,
          });
          excelData.push({
            id: startIndex + i,
            mobile: data?.mobile,
            status: data?.status,
            created_at: data?.created_at,
            transaction_id: data?.transaction_id,
            latitude: data?.latitude,
            longitude: data?.longitude,
            location:
              data.latitude && data.longitude
                ? `=HYPERLINK("https://maps.google.com/?q=${data.latitude},${data.longitude}", "View Location")`
                : 'No Location Available',
            IP: data?.IP,
            Device: data?.Device,
          });
        });
        setAllExcelData(excelData);
        setData(result);
        setLoading(false);
        setPagination(response?.data?.result?.totalCount);
      } else {
        Swal.fire({
          title: '',
          text: 'Error while fetching users details',
          icon: 'error',
          showCancelButton: false,
          confirmButtonText: 'Got it',
        }).then((result) => {
          if (result.isConfirmed) {
            navigate('/main');
          }
        });
      }
    } catch (err) {
      console.log('Error', err);
    }
  };

  const handleExportClick = () => {
    exportToXLSX(allExcelData, 'login_report.csv');
  };

  useEffect(() => {
    getAllData(page, size, selectedDate); // Pass selectedDate to the API call
  }, [page, size, selectedDate]);

  const handleDateChange = (date) => {
    const formattedDate = format(date, 'yyyy-MM-dd');
    setSelectedDate(formattedDate); // Update selectedDate state with formatted date
  };

  const handleClearFilter = () => {
    setSelectedDate('');
    getAllData(page, size, selectedDate);
  };

  const handleDateBlur = () => {
    getAllData(page, size, selectedDate);
  };

  return (
    <>
      <PageLayout>
        {loading ? (
          <Loader />
        ) : (
          <div
            className="ms-4"
            style={{ height: 400, width: '95%', marginTop: '10px' }}
          >
            <div className="d-flex justify-content-between align-items-center">
              <button
                type="button"
                className="btn btn-warning ms-3 mb-2 mt-2"
                onClick={() => {
                  navigate(-1);
                }}
              >
                Go Back
              </button>
              <div className="d-flex align-items-center justify-content-end">
                <DatePicker
                  selected={selectedDate}
                  onChange={handleDateChange}
                  placeholderText="Select date"
                  onBlur={handleDateBlur}
                  dateFormat="dd-MM-yyyy" // Adjust date format as needed
                  className="me-3"
                  maxDate={new Date()}
                />
                {selectedDate && ( // Render clear filter button only if a date is selected
                  <button
                    style={{ marginRight: '5px' }}
                    className="btn btn-info mb-2"
                    type="button"
                    onClick={handleClearFilter}
                  >
                    Clear Filter
                  </button>
                )}
                {data.length > 0 && (
                  <button
                    className="btn btn-info mb-2"
                    type="button"
                    onClick={handleExportClick}
                  >
                    Download
                  </button>
                )}
              </div>
            </div>

            <DataGrid
              sx={{ marginLeft: '15px' }}
              rows={data}
              columns={columns}
              rowsPerPageOptions={[10, 20, 50, 100]}
              // pagination
              // pageSize={size}
              // rowCount={pagination}
              // paginationMode="server"
              // onPageChange={(newPage) => setPage(newPage + 1)}
              // onPageSizeChange={(newPageSize) => setSize(newPageSize)}
              // components={{
              //   Toolbar: CustomToolbar,
              // }}
              autoHeight
              {...data}
              // slots={{ toolbar: GridToolbar }}
              components={{
                NoRowsOverlay: () => (
                  <div
                    style={{ fontSize: '20px', margin: '10px' }}
                    className="result-not-found"
                  >
                    Data Not Found
                  </div>
                ),
              }}
            />
          </div>
        )}
      </PageLayout>
    </>
  );
}

export default LoginDetails;
